@import '../../variables';

.homepage {
  height: 100%;
  
  @include media-breakpoint-down(sm) {
    // scroll past the bottom of the page on mobile
    > *:last-child {
      margin-bottom: 4em;
    }
  }

  &__header {
    padding: 2em;
  }

  &__next-steps {
    margin-top: 2em;

    .btn-link {
      padding: 0;
      vertical-align: unset;
    }

    &__todo-list {
      font-size: 18px;

      .btn {
        font-size: 18px;
      }
    }
    
    &__todo-list-item {
      @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-bottom: 1em;
          border-bottom: 1px solid $gray-600;
        }
      }
      
      .btn-link {
        text-align: left;
        vertical-align: top;
      }
    }
  }
}
