@import '../../variables';

.notifications {
  position: fixed;
  bottom: 0;
  right: 0;
  left: $top-nav-desktop-width;
  
  @include media-breakpoint-down(sm) {
    left: 0;
    bottom: $top-nav-mobile-height;
  }
}
