
.onboarding {
  &__flow-state {
    padding: 20px;
    border-top: 1px solid black;
    text-align: center;

    &__step {
      &.last {
        color: gray;
      }

      &.current {
        text-decoration: underline;
      }

      &:not(:last-child) {
        border-right: 1px solid black;
      }
    }
  }
}
