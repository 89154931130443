
.copyable-span {
  cursor: pointer;
  
  .material-icons {
    font-size: 1em;
    margin-left: 0.5em;
    vertical-align: text-bottom;
  }
}
