@import '../../../variables';


.incidents-show {
  
  @include media-breakpoint-down(sm) {
    // scroll past the bottom of the page on mobile
    > *:last-child {
      margin-bottom: 4em;
    }
  }


  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__actions {
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      // On mobile, let header take up the whole line and float buttons
      // to same baseline as "patient"
      display: flex;
      flex-direction: column;
      margin-bottom: -2.25em;
    }
  }

  &__info {
    font-size: 18px;
  }

  &__badges {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }

  .incident-submit-button {
    margin: 1em 0 2em 0;
    width: 100%;

    &__toggle {
      width: 100%;
    }
  }

  &__expenses {
    margin-top: 20px;

    &-header {
      margin: 0.5em 0;
      display: flex;

      h6 {
        white-space: nowrap;
      }

      > * {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child {
          align-items: flex-start;
        }

        &:last-child {
          align-items: flex-end;
        }
      }

      .btn-link {
        font-size: 0.7rem;
        white-space: nowrap;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-add {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__submissions {
    margin-top: 20px;
  }
}
