
.attachment {
  display: flex;
  text-decoration: unset;
  color: inherit;
  &:hover {
    color: inherit;
  }

  border-top: 1px dotted rgba(gray, 0.2);

  &.disabled {
    pointer-events: none;
  }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin-right: auto;
  }

  &__download-icon {
    cursor: pointer;
  }

  &__delete-icon {
    cursor: pointer;
    color: red;
  }
}
