@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.expenses {
  height: 100%;
  
  @include media-breakpoint-down(sm) {
    // scroll past the bottom of the page on mobile
    > *:last-child {
      margin-bottom: 4em;
    }
  }

  .hr {
    margin: 20px 0;
  }

  &__title.row {
    margin-top: 2em;
  }

  &__row {
    min-height: 40vh;

    &-header {
      margin: 0.5em 0;
      display: flex;

      h6 {
        white-space: nowrap;
      }

      > * {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:first-child {
          align-items: flex-start;
        }

        &:last-child {
          align-items: flex-end;
        }
      }

      .btn-link {
        font-size: 0.7rem;
        white-space: nowrap;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .overline {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
