.attach-receipt-action {



  .dropzone {
    height: 100%;

    [role="presentation"] {
      height: 100%;
      min-height: 10rem;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      border: 1px dotted black;
      padding: 1rem;
      border-radius: 0.25rem;
      cursor: pointer;

      .dropzone__content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

}
