@import '../../variables';

.help-search {
  &__result {
    margin-top: 0.5rem;
    cursor: pointer;
    
    .card-text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    
    .card-subtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .btn-link {
      color: $orange;
      display: flex;
      text-decoration: none;
      
      i {
        transition: right 0.3s ease-in-out;
        right: 0;
        position: relative;
      }
    }
    
    &:hover {
      .btn-link {
        color: $orange;

        i {
          right: -10px;
        }
      }
    }
  }
}

.ais-SearchBox {
  &-form {
    display: flex;
    flex-direction: row-reverse;
    position: sticky;
    width: 100%;
  }

  &-input[type='search'] {
    line-height: 1.4;
    // room for absolutely-positioned submit/reset buttons
    padding: 2em .75em 2em 2.75em;
    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  &-submit,
  &-reset,
  &-loadingIndicator {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    cursor: pointer;
    left: .5em;
    padding: .25em;
    position: absolute;
    top: 1.75em;
    z-index: 2; // This is needed to allow the search icon to appear in the search box.

    svg {
      height: 1em;
      width: 1em;
    }
  }
  
  .ais-SearchBox-reset {
    display: none;
  }
  
  &.has-query {
    .ais-SearchBox-submit {
      display: none;
    }
    .ais-SearchBox-reset {
      display: block;
    }
  }
}

.help-ask {
  &__submit {
    margin-top: 1rem;
    float: right;
    
    &.btn {
      display: flex;
      align-items: center;
    }
    
    i {
      transition: right 0.3s ease-in-out;
      right: 0;
      position: relative;
      margin-left: 0.5rem;
    }
      
    &:hover {
      i {
        right: -10px;
      }
    }
  }
}
