
.todo-list {

  &-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    &__header {
      display: flex;

      i {
        margin-right: 1em;
      }
    }

    &.complete {
      background-color: rgba(gray, 0.1);
    }
  }
}
