
.bulk-add-expense {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__upload,
  &__spreadsheet,
  &__done {
    margin-top: 1em;
  }

  &__upload {
    &-preview {
      &.row {
        margin-top: 1em;
      }

      .card-text {
        text-align: center;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .dropzone {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      height: 100%;
      width: 100%;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .card-text {
      text-align: center;
    }
  }
}
