
@mixin material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;

  html.font-material-icons-loading &,
  html.font-material-icons-failed & {
    visibility: hidden;
  }
  html.font-material-icons-loaded & {
    max-width: unset;
    visibility: visible;
  }
}

@mixin material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;

  html.font-material-icons-outlined-loading &,
  html.font-material-icons-outlined-failed & {
    visibility: hidden;
  }
  html.font-material-icons-outlined-loaded & {
    visibility: visible;
  }
}
