@import '../../../variables';

.new-expense-row-form {

  .dropdown-menu {
    @include media-breakpoint-down(sm) {
      right: 0;
      width: unset;
      min-width: 100%;
    }
  }

  .form-select {
    &.disabled {
      color: $input-disabled-color;
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;
    }
  }
  
  &__form-check-wrapper {
    padding: 0.25em 1.5em;
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
