
.sign-in {
  .nav-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .nav-link {
      padding: 0.5em;
    }
  }
}
