@import '../../styles/material_icons_mixins.scss';

.incident-submit-button {
  &__toggle {
    &::after {
      @include material-icons();
      float: right;
    }

    &[aria-expanded='false']::after {
      content: 'arrow_drop_down';
    }

    &[aria-expanded='true']::after {
      content: 'arrow_drop_up';
    }

    &[data-can-submit="true"]::after {
      content: 'arrow_forward';
    }
  }
}
