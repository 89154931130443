@import '../../variables';

.dropdown-select {
  &.form-select {
    padding: 0;

    .dropdown-toggle {
      width: 100%;
      text-align: left;
      display: flex;

      &:after {
        // form-select includes a carat already
        display: none;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
  }
  .dropdown-item {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $body-color;
    
    .muted {
      color: $text-muted;
    }
  }

  &.disabled {
    .dropdown-toggle {
      border: 0;
    }
  }

  &.invalid {
    border: 1px solid $danger;
  }
}
