@import '../../variables';

.todos {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.row {
    margin-top: 2em;
  }

  &__list-item {
    display: flex;
    height: 24px;
    @include media-breakpoint-down(sm) {
      height: 48px;
    }

    &__content {
      display: flex;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid $gray-300;
    }

    &.expanded {
      .todos__list-item__content {
        border-bottom-color: transparent;
      }
    }
    
    &.complete {
      @include media-breakpoint-down(sm) {
        opacity: 0.3;
      }
    }
    
    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-height: 48px;
    }

    &__expander {
      width: 42px;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid $gray-300;
      border-left: 1px solid $gray-300;
      
      i {
        // margin-top: 16px;
      }
    }
    
    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      max-height: 24px;
    }

    &__due-date {
      margin: 0.5em;
    }
    
    &.isChild {
      .todos__list-item__content {
        border-bottom-color: transparent;
      }
      
      .todos__list-item__expander {
        border-bottom-color: transparent;
        height: 72px;
      }
    }
  }

  [aria-level="0"] {
    .todos__list-item__expander {
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
}
