@import '../../variables';

.quick-add {
  .card {
    img {
      max-width: 100%;
      max-height: 40vh;
    }
  }

  .underline {
    font-size: 12px;
    font-style: italic;
  }

  &__blob {
    &__delete {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }

  &__snap,
  &__manual  {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin: 2em 0;
    }

    h6 {
      margin-bottom: 1.5rem;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .snap-a-photo {
    margin-top: 1em;
  }

  .dropzone {
    [role="presentation"] {
      height: 4rem;
      width: 4rem;
      display: flex;
      background-color: $primary;
      border-radius: 0.25rem;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        // On phones we want bigger buttons
        height: 8rem;
        width: 8rem;
      }

      .dropzone__content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__separator {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;

    &::before {
      content: ' ';
      border: 1px solid black;
      display: block;
      position: absolute;
      left: 50%;
      height: 100%;
    }

    &-or {
      background: white;
      z-index: 1;
      padding: 1em;
      text-align: center;
    }

    @include media-breakpoint-down(lg) {
      &::before {
        left: 0;
        height: 1px;
        top: 50%;
        width: 100%;
      }

      &-or {
        padding: 1em;
      }
    }
  }

  &__keyboard-button {
    height: 4rem;
    width: 4rem;
    border-radius: 0.25rem;
    margin-top: 1em;

    @include media-breakpoint-down(sm) {
      // On phones we want bigger buttons
      height: 8rem;
      width: 8rem;
    }
  }
}
