@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../styles/material_icons_mixins";

.expense-table {
  margin-top: 0.5em;

  &__row {
    margin-bottom: 0.5em;

    &.submitted {
      background-color: rgba(gray, 0.1);
      border-color: rgba(gray, 0.1);
    }

    &.expanded {
      margin-bottom: 1.5em;
    }
  }

  &__row-header {
    cursor: pointer;

    &.expanded {
      cursor: inherit;
    }

    .btn-link {
      padding: 0;
    }

    .card-title {
      display: flex;
    }

    [data-bs-toggle="collapse"] {
      &::before {
        @include material-icons();
        content: 'expand_more';
      }

      &[aria-expanded="true"] {
        &::before {
          content: 'expand_less';
        }
      }
    }


    .card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 2em;
    }
  }

  &__row-collapse {
    position: sticky;

    &-toggle {
      position: absolute;
      right: 0.5em;
      top: 0.5em;
    }
  }

  &__widget {
    margin: 1em 0;

    .overline {
      height: 2em;
    }

    &__attachments {
      margin-top: 1em;
    }

    &__actions {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
    }

    &-incident {
      color: inherit;
      text-decoration: unset;

      span {
        border-bottom: 1px solid transparent;
      }

      .material-icons {
        margin-right: 0.5em;
        transition: margin 400ms;
      }

      &:hover,
      &:focus {
        color: inherit;

        span {
          border-bottom: 1px solid black;
        }

        .material-icons {
          margin-right: 0;
        }
      }
    }
  }

  .attachment {
    display: flex;
    padding: 0.5rem 0;

    &:last-child {
      border-bottom: 0;
    }

    &__name {
      margin-left: 0.5rem;
    }

    &__actions {
      margin-left: auto
    }

    .dropdown-toggle::after {
      display: none;
    }
  }

  .card {
    border-radius: 0;
    border-width: 2px;

    &.expanded {
      box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
