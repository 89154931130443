
@import '../fonts/figtree';
@import '../fonts/karla';
@import '../variables';

@mixin interface-label() {
  font-family: 'Karla', sans-serif;
  text-transform: uppercase;
}

.interface-label {
  @include interface-label();
}

.overline {
  @include interface-label();
  font-size: $font-size-sm;
}

.btn:not(.btn-link,.dropdown-toggle) {
  @include interface-label();
}

.input-group-text {
  @include interface-label();
}


h1 {
  color: $orange;
}

.form-label {
  @include interface-label();
}

// .form-check-label {
//   @include interface-label();
//   text-transform: unset;
//   font-size: $font-size-sm;
// }

.disclaimer {
  font-family: 'figtree', sans-serif;
  font-size: $font-size-sm;
  opacity: 0.8;
}
