
.expense-spreadsheet {
  &.table {
    table-layout: fixed;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
