@import "../../styles/material_icons_mixins";

.submission-history {
  &__row {
    margin-bottom: 0.5em;
  }

  &__row-header {
    cursor: pointer;

    .btn-link {
      padding: 0;
    }

    [data-bs-toggle="collapse"] {
      &::before {
        @include material-icons();
        content: 'expand_more';
      }

      &[aria-expanded="true"] {
        &::before {
          content: 'expand_less';
        }
      }
    }

    .card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 2em;
    }
  }

  &.expanded {
    .submission-history__row-header {
      cursor: inherit;
    }
  }

  &__row-collapse {
    position: sticky;

    &-toggle {
      position: absolute;
      right: 0.5em;
      top: 0.5em;
    }
  }

  &__widget {
    margin: 1em 0;

    .overline {
      height: 2em;
    }

    &__attachments {
      margin-top: 1em;
    }

    &__actions {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
    }

    &-incident {
      color: inherit;
      text-decoration: unset;

      span {
        border-bottom: 1px solid transparent;
      }

      .material-icons {
        margin-right: 0.5em;
        transition: margin 400ms;
      }

      &:hover,
      &:focus {
        color: inherit;

        span {
          border-bottom: 1px solid black;
        }

        .material-icons {
          margin-right: 0;
        }
      }
    }

    &-todos {
      border: 1px solid rgba(gray, 0.1);
      border-radius: 4px;
      padding: 0.5em;
      margin-bottom: 1.5em;
      box-shadow: -2px 1px 2px gray;
    }
  }
  
  &__widget-expenses-row {
    td:last-child {
      text-align: right;
    }
  }



  .card {
    border-radius: 0;
    border-width: 2px;



    &.expanded {
      box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
