$font-family-sans-serif:
  Figtree,
  Lato,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$navy: #01017a;
$ultramarine: #0f0ade;
$ecstasy: #f9690e;
$gray: #4F4F52;

// $body-bg: $navy;
// $body-color: #fff;

$blue: $ultramarine;
$orange: $ecstasy;

$primary: $blue;
$danger: $orange;
// $secondary: $orange;
// $success: #4F4F52;

@import "~bootswatch/dist/flatly/variables";

$link-color: $cyan;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$top-nav-desktop-width: 160px;
$top-nav-mobile-height: 73px;
