@import '../../../variables';

.dependents-form {
  .table {
    table-layout: fixed;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover, &:focus {
        overflow: unset;
      }

      .btn {
        padding: 0;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}
