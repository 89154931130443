.signature-canvas {
  position: sticky;

  &__actions {
    position: absolute;
    right: 1px;
    top: 1px;
    background-color: white;
    z-index: 2;

    a {
      cursor: pointer;
    }
  }

  .ratio {
    --bs-aspect-ratio: calc(9 / 42 * 100%);
  }

  canvas {
    border: 1px solid black;
    z-index: 1;
  }
}
